exports.linkResolver = doc => {
  // URL for a category type
  if (doc.type === "home") {
    return `/`;
  }

  // URL for a product type
  if (doc.type === "exhibition") {
    return `/exhibition/${doc.uid}/`;
  }

  // URL for a page type
  if (doc.type === "artist") {
    return `/artist/${doc.uid}/`;
  }

  // Backup for all other types
  return `/${doc.uid}/`;
};
