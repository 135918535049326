import React, { useEffect } from "react";
import styled from "styled-components";

const Main = styled.main`
  flex: 1;
`;

export const App = ({ location, data, children }) => {
  useEffect(() => {
    document.body.style.overflow = "";
  }, [location]);

  return <Main>{children}</Main>;
};
