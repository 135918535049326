import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

import styled from "styled-components";

const ArtistsContainer = styled.div`
  align-self: flex-start;
  width: 100%;

  // margin: 1em 0;

  // @media (max-width: 900px) {
  //   margin: 40px 0;
  // }

  // & h1 {
  //   margin: 0 0 1em 0;
  // }

  & ol {
    margin: 1em 0;
  }

  & .single-artist {
    & p {
      margin: 0;

      & .active-artist {
        opacity: 0;
        visibility: hidden;
        transition: 250ms opacity ease;
      }
    }

    & a.active {
      & p {
        & .active-artist {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    &:hover {
      & .active-artist {
        opacity: 1;
        visibility: visible;
      }
    }

    & a {
      transition: 150ms color ease;

      &:hover {
        color: #6f6d6d;
      }
    }
  }
`;

export const Artists = () => {
  const data = useStaticQuery(graphql`
    {
      allPrismicArtist(sort: { fields: data___title___text, order: ASC }) {
        edges {
          node {
            url
            id
            data {
              title {
                text
              }
            }
          }
        }
      }
    }
  `);

  const sortedByNames = data.allPrismicArtist.edges.slice().sort((a, b) => {
    let [aNames, aSurname] = a.node.data.title.text
      .match(/(.*)\s(\w+)$/)
      .slice(1);
    let [bNames, bSurname] = b.node.data.title.text
      .match(/(.*)\s(\w+)$/)
      .slice(1);

    if (aSurname.localeCompare(bSurname))
      return aSurname.localeCompare(bSurname);
    else return aNames.localeCompare(bNames);
  });

  const artistList = sortedByNames.map((artist, index) => (
    <li key={artist.node.id} className="single-artist">
      <Link to={artist.node.url} activeClassName="active">
        <p>
          – {artist.node.data.title.text}{" "}
          <span className="active-artist"> ←</span>
        </p>
      </Link>
    </li>
  ));

  return (
    <ArtistsContainer>
      <ol>{artistList}</ol>
    </ArtistsContainer>
  );
};
