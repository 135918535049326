import * as React from "react";
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from "gatsby-plugin-prismic-previews";

// Prismic
import { linkResolver } from "./src/components/link-resolver/linkResolver";

// Pages
import ArtistsPage from "./src/pages/artists";
import ExhibitionsPage from "./src/pages/exhibitions";
import Index from "./src/pages/index";

import Artist from "./src/templates/artist";
import Exhibition from "./src/templates/exhibition";
import Page from "./src/templates/page";

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: `adzgallery`,
        linkResolver,
        componentResolver: componentResolverFromMap({
          artists: ArtistsPage,
          exhibitions: ExhibitionsPage,
          index: Index,
          artist: Artist,
          exhibition: Exhibition,
          page: Page,
        }),
      },
    ]}
  >
    {element}
  </PrismicPreviewProvider>
);
