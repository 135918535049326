import React from "react";
import styled from "styled-components";
import { use100vh } from "react-div-100vh";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// Components
import { Address } from "../components/layout/address";
import { Exhibitions } from "../components/layout/exhibitions";
import { Mailchimp } from "../components/forms/mailchimp";
import { Navigation } from "../components/layout/navigation";

// Icons
import { Logo } from "../components/icons/logo";
import { Artists } from "../components/layout/artists";
import { CurrentExhibition } from "../components/layout/current-exhibition";

const Page = styled.div`
  padding: 20px;

  & .left-column {
    min-height: calc(${props => props.height}px - 40px);

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    & ol {
      & li {
        & a {
          transition: 250ms color ease;

          & span {
            opacity: 0;
            transition: 250ms opacity ease;
          }

          &:hover,
          &.current {
            color: #6f6d6d;

            & span {
              opacity: 1;
            }
          }
        }
      }
    }

    @media (max-width: 767px) {
      max-height: calc(${props => props.height}px - 20px);
    }
  }

  & .right-column {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    max-height: calc(${props => props.height}px - 20px - 74px);

    @media (max-width: 900px) {
      max-height: calc(${props => props.height}px - 20px);
    }

    & svg {
      width: auto;
      height: auto;
      max-height: 100%;
      max-width: 600px;

      margin: auto 0 0 auto;
    }

    @media (max-width: 767px) {
      display: none;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 400px auto;
  grid-gap: 20px;

  @media (max-width: 1020px) {
    grid-template-columns: 290px 1fr;
  }

  @media (max-width: 767px) {
    display: block;
    grid-template-columns: unset;
    grid-gap: unset;
  }
`;

const ExhibitionsPage = () => {
  const height = use100vh();

  return (
    <Page height={height}>
      <Grid>
        <div className="left-column">
          <div className="content-container">
            <Address />

            <CurrentExhibition />

            <Navigation showExhibitions={true} />
          </div>

          <Mailchimp />
        </div>
      </Grid>
    </Page>
  );
};

export default withPrismicPreview(ExhibitionsPage);
