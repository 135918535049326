import React, { useContext } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { use100vh } from "react-div-100vh";
import Media from "react-media";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// Context
import WindowWidthContext from "../components/context/window-width";

// Components
import { Address } from "../components/layout/address";
import { CurrentExhibition } from "../components/layout/current-exhibition";
import { Mailchimp } from "../components/forms/mailchimp";
import { Navigation } from "../components/layout/navigation";

const PageContainer = styled.div`
  padding: 20px;

  & .header {
    max-width: 760px;
  }

  & .left-column {
    min-height: calc(${props => props.height}px - 40px);

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media (max-width: 767px) {
      display: block;

      min-height: 100%;
    }

    & .content-container {
      width: 100%;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 20px;

  @media (max-width: 1020px) {
    grid-template-columns: 290px 1fr;
  }

  @media (max-width: 767px) {
    display: block;
    grid-template-columns: unset;
    grid-gap: unset;
  }
`;

const Container = styled.div`
  max-width: 760px;

  & h1 {
    margin: 0;

    font-size: 16px;
    line-height: 1.15;
  }

  & h2 {
    font-size: 16px;
    line-height: 1.15;
    margin: 0;
  }
`;

const Page = ({ data, location }) => {
  const height = use100vh();
  const windowWidth = useContext(WindowWidthContext);

  const content = data.prismicPage.data.body.map((content, index) => (
    <div key={`single_item_${index}`} className="single-text-box">
      <div
        className="text"
        dangerouslySetInnerHTML={{
          __html: content.primary.text.html,
        }}
      />
    </div>
  ));

  return (
    <>
      <Helmet
        title={`${data.prismicPage.data.title.text} | ${data.prismicSeo.data.seo_title}`}
        meta={[
          {
            name: "title",
            content: `${data.prismicPage.data.title.text} | ${data.prismicSeo.data.seo_title}`,
          },
          {
            name: "og:title",
            content: `${data.prismicPage.data.title.text} | ${data.prismicSeo.data.seo_title}`,
          },
          {
            name: "description",
            content: `${data.prismicSeo.data.seo_description.text}`,
          },
          {
            name: "og:description",
            content: `${data.prismicSeo.data.seo_description.text}`,
          },
          {
            name: "twitter:title",
            content: `${data.prismicPage.data.title.text} | ${data.prismicSeo.data.seo_title}`,
          },
          {
            name: "twitter:description",
            content: `${data.prismicSeo.data.seo_description.text}`,
          },
          {
            property: "og:url",
            content: location.href,
          },
          {
            property: "twitter:url",
            content: location.href,
          },
        ]}
      />
      <PageContainer height={height}>
        <Grid>
          <div className="left-column">
            <div className="content-container">
              <Address />

              <CurrentExhibition />

              <Navigation
                showExhibitions={false}
                showArtists={false}
                showBackButton={false}
              />
            </div>

            <Media
              queries={{ medium: "(min-width: 901px)" }}
              defaultMatches={{ medium: windowWidth === 901 }}
              render={() => <Mailchimp />}
            />
          </div>

          <div className="right-column">
            <Container>{content}</Container>
          </div>

          <Media
            queries={{ medium: "(max-width: 900px)" }}
            defaultMatches={{ medium: windowWidth === 900 }}
            render={() => (
              <div className="mobile-mailchimp-container">
                <Mailchimp />
              </div>
            )}
          />
        </Grid>
      </PageContainer>
    </>
  );
};

export default withPrismicPreview(Page);

export const query = graphql`
  query Page($uid: String!) {
    prismicSeo {
      data {
        seo_title
        seo_description {
          text
        }
      }
    }
    prismicPage(uid: { eq: $uid }) {
      data {
        title {
          html
          text
        }
        body {
          ... on PrismicPageDataBodyText {
            id
            slice_type
            primary {
              text {
                html
              }
            }
          }
        }
      }
    }
  }
`;
