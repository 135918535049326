import React from "react";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
body {
  font-family: "Times New Roman", "Times", TimesNewRoman, Times New Roman, Times, Baskerville, Georgia, serif;
  font-weight: normal;
  font-style: normal;

  font-size: 16px;
  line-height: 1.15;
  letter-spacing: 0.15px;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-smooth: always;
  font-variant: historical-ligatures;
  font-feature-settings: "hlig" 1, "dlig" 1, "onum" 1, "zero" 1, "swsh" 1, "kern" 1, "medi" 1;
  letter-spacing: -0.004em;

  -webkit-tap-highlight-color: transparent;

  color: #000;
  margin: 0;

  word-break: break-word;

  // @media (max-width: 1020px) {
  //   font-size: 16px;
  //   line-height: 20px;
  // }
}

input {
  border-radius: 0;
}

h1,h2,h3,h4,h5{
  font-family: "Times New Roman", "Times", TimesNewRoman, Times New Roman, Times, Baskerville, Georgia, serif;
  font-weight: normal;

  font-size: 16px;
  line-height: 1.15;
  letter-spacing: 0.15px;

  margin: 0;

  // @media (max-width: 1020px) {
  //   font-size: 16px;
  //   line-height: 20px;
  // }
}

/*--------------------------------------------------------------
  ## Media
  --------------------------------------------------------------*/

video,
img {
  width: 100%;
  margin: 0;

  display: block;
}

/*--------------------------------------------------------------
  ## Buttons
  --------------------------------------------------------------*/

  button{
    background-color: transparent;
    border:0;
    padding:0;
    margin:0;
    -webkit-appearance:none;
    font-weight: inherit;

    cursor:pointer;

    &:focus,
    &:hover{
      outline: none;
    }
  }

/*--------------------------------------------------------------
  ## Links
  --------------------------------------------------------------*/
a {
  color: inherit;
  text-decoration: none;

  position: relative;
}

a:visited {
  color: inherit;
}

a:hover,
a:focus,
a:active {
  color: inherit;
}

a:hover,
a:active,
a:focus {
  outline: 0;
}

/*--------------------------------------------------------------
  ## Text
  --------------------------------------------------------------*/

p {
  margin: 1em 0;
}

ul, ol, li{
  list-style: none;
  padding: 0;
  margin: 0;
}

/*--------------------------------------------------------------
  ## Box Sizing / Layout
  --------------------------------------------------------------*/

div {
  box-sizing: border-box;
}

/*--------------------------------------------------------------
## General modals
--------------------------------------------------------------*/

body.ReactModal__Body--open{
  overflow: hidden;
}

.ReactModalPortal,
.ReactModal__Overlay--after-open {
		z-index: 1000;
    border: 0;
    background-color: #fff !important;
    overflow-y: scroll;

    height: 100%;
}

.product-lighbox-modal{
  height: 100%;
}
`;

export default GlobalStyle;
