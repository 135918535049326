import React from "react";
import styled from "styled-components";

const Icon = styled.svg`
  width: 100%;
  height: auto;
`;

export const Logo = () => (
  <Icon
    width="1493"
    height="1500"
    viewBox="0 0 1493 1500"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M171.254 1415.57H73.612L56.5074 1455.32C53.1158 1462.21 50.9817 1469.65 50.2057 1477.28C50.2245 1479.26 50.7034 1481.22 51.6046 1482.98C52.5059 1484.75 53.8049 1486.28 55.3994 1487.46C62.699 1491.36 70.8827 1493.29 79.152 1493.07V1500H0V1493.07C7.4164 1492.65 14.5863 1490.26 20.7748 1486.15C29.8911 1475.44 36.936 1463.13 41.5496 1449.85L130.258 1242.06H136.767L224.506 1451.86C228.675 1463.98 235.22 1475.14 243.758 1484.69C250.612 1490.14 259.102 1493.12 267.856 1493.14V1500.07H168.276V1493.14C175.42 1493.45 182.503 1491.72 188.704 1488.16C190.328 1486.95 191.648 1485.39 192.562 1483.58C193.475 1481.78 193.957 1479.79 193.967 1477.77C193.019 1468.77 190.511 1460 186.558 1451.86L171.254 1415.57ZM166.06 1401.71L123.264 1299.82L79.429 1401.78L166.06 1401.71Z"
      fill="black"
    />
    <path
      d="M881.959 1140.31V1133.38H891.446C895.796 1133.79 900.176 1133.06 904.158 1131.26C908.14 1129.46 911.587 1126.66 914.16 1123.13C916.861 1119.04 918.246 1109.83 918.246 1095.42V932.721C918.246 916.998 916.515 907.093 912.983 903.145C910.215 900.282 906.849 898.066 903.127 896.653C899.404 895.24 895.416 894.666 891.446 894.971H881.959V888.045H984.587C1022.3 888.045 1050.97 892.339 1070.59 900.928C1090.57 909.738 1107.19 924.716 1118.03 943.665C1130.16 964.671 1136.27 988.611 1135.69 1012.86C1136.69 1045.21 1125.27 1076.71 1103.76 1100.9C1079.8 1127.12 1043.33 1140.26 994.351 1140.31H881.959ZM953.909 1122.09C966.895 1125.21 980.171 1126.95 993.52 1127.29C1006.93 1127.63 1020.26 1125.11 1032.61 1119.87C1044.96 1114.64 1056.04 1106.82 1065.12 1096.95C1084.1 1076.72 1093.59 1049.32 1093.59 1014.73C1093.59 980.144 1084.1 952.692 1065.12 932.375C1055.8 922.419 1044.46 914.564 1031.86 909.333C1019.26 904.102 1005.7 901.615 992.066 902.036C979.179 902.282 966.369 904.072 953.909 907.37V1122.09Z"
      fill="black"
    />
    <path
      d="M1489.9 0L1324.19 237.094H1427.57C1440.61 237.961 1453.53 234.263 1464.14 226.635C1472.66 219.708 1480.13 205.855 1486.71 184.452L1492.88 185.561L1480.97 252.332H1275.64V245.405L1437.48 15.4461H1356.73C1346.84 14.8639 1336.93 16.3501 1327.65 19.8098C1321.83 22.5029 1316.99 26.9308 1313.8 32.4852C1309.34 42.3008 1306.42 52.7433 1305.14 63.4466H1298.22L1303.41 0.346325L1489.9 0Z"
      fill="black"
    />
  </Icon>
);
