import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";

const AddressContainer = styled.div`
  align-self: flex-start;
  width: 100%;

  margin: ${props => props.margin};

  @media (max-width: 767px) {
    margin: 0 0 40px 0;
  }

  & h1,
  & p {
    font-size: 16px;
    line-height: 1.15;
    // font-size: 20px;
    // line-height: 24px;
    margin: 0;

    // @media (max-width: 1020px) {
    //   font-size: 18px;
    //   line-height: 22px;
    // }

    // @media (max-width: 769px) {
    //   font-size: 16px;
    //   line-height: 20px;
    // }
  }

  & a {
    transition: 150ms color ease;

    &:hover {
      color: #6f6d6d;
    }
  }
`;

export const Address = ({ margin }) => {
  const data = useStaticQuery(graphql`
    {
      prismicHomepage {
        data {
          address {
            html
          }
        }
      }
    }
  `);

  return (
    <AddressContainer margin={margin === false ? `0` : `0 0 100px 0`}>
      <h1>
        <Link to={`/`}>ADZ</Link>
      </h1>
      <div
        className="address"
        dangerouslySetInnerHTML={{
          __html: data.prismicHomepage.data.address.html,
        }}
      />
    </AddressContainer>
  );
};
