import React from "react";

export const windowWidth = {
  windowWidth: 1024,
  updateWindowWidth: () => {},
};

const WindowWidthContext = React.createContext(windowWidth);

export default WindowWidthContext;
