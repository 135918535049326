import React, { useState, useEffect } from "react";
import { Location } from "@reach/router";
import { Normalize } from "styled-normalize";

// Styles
import GlobalStyles from "../styles/globalStyles";

// Context
import WindowWidthContext from "../context/window-width";

// Components
import { App } from "./app";
import { DefaultSEO } from "./default-seo";

const Layout = ({ children }) => {
  // Window Width
  const [updatedWindowWidth, setWindowWidth] = useState(1024);
  useEffect(() => {
    if (typeof window !== `undefined`) {
      let getWindowWidth = window.innerWidth;
      setWindowWidth(getWindowWidth);
    }
  }, []);

  useEffect(() => {
    console.log(
      "%cDevelopment: Kieran Startup: www.kieranstartup.co.uk",
      "color:red; font-family:Helvetica Neue;  font-size: 20px"
    );
  }, []);

  return (
    <Location>
      {({ location }) => (
        <>
          <WindowWidthContext.Provider value={updatedWindowWidth}>
            <Normalize />
            <GlobalStyles />
            <DefaultSEO location={location} />
            <App location={location.pathname} children={children} />
          </WindowWidthContext.Provider>
        </>
      )}
    </Location>
  );
};

export default Layout;
