import React, { useState, useContext } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import Media from "react-media";
import { use100vh } from "react-div-100vh";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// Context
import WindowWidthContext from "../components/context/window-width";

// Components
import { Address } from "../components/layout/address";
import { CurrentExhibition } from "../components/layout/current-exhibition";
import { Lightbox } from "../components/images/lightbox";
import { Mailchimp } from "../components/forms/mailchimp";
import { Navigation } from "../components/layout/navigation";

const Page = styled.div`
  padding: 20px;

  & .header {
    max-width: 760px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 20px;

  & .left-column {
    & .sticky {
      position: sticky;
      top: 20px;

      min-height: calc(${props => props.height}px - 40px);

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      @media (max-width: 767px) {
        display: block;

        min-height: 100%;
      }

      & .content-container {
        width: 100%;
      }
    }
  }

  & .right-column {
    & a {
      text-decoration: underline;
    }
  }

  @media (max-width: 1020px) {
    grid-template-columns: 290px 1fr;
  }

  @media (max-width: 767px) {
    display: block;
    grid-template-columns: unset;
    grid-gap: unset;
  }
`;

const ArtworksContainer = styled.div`
  & ol {
    & li {
      cursor: pointer;

      margin: 0 0 2em 0;
    }
  }
`;

const Artist = ({ data, location }) => {
  const [artwork, setArtwork] = useState(null);
  const [isLightboxOpen, setLightBoxOpen] = useState(false);
  const windowWidth = useContext(WindowWidthContext);

  const height = use100vh();

  const artworks = data.prismicArtist.data.artworks.map((artwork, index) => (
    <li
      key={`single_artwork_${index}_${data.prismicArtist.id}`}
      className="single-artwork"
    >
      {artwork.image.fluid !== null && (
        <img
          sizes={artwork.image.fluid.sizes}
          srcSet={artwork.image.fluid.srcSet}
          src={artwork.image.fluid.src}
          alt={artwork.image.alt}
          loading={`lazy`}
          onClick={() => {
            setArtwork(artwork);
            setLightBoxOpen(true);
          }}
        />
      )}
    </li>
  ));

  return (
    <>
      <Helmet
        title={`${data.prismicArtist.data.title.text} | ${data.prismicSeo.data.seo_title}`}
        meta={[
          {
            name: "title",
            content: `${data.prismicArtist.data.title.text} | ${data.prismicSeo.data.seo_title}`,
          },
          {
            name: "og:title",
            content: `${data.prismicArtist.data.title.text} | ${data.prismicSeo.data.seo_title}`,
          },
          {
            name: "description",
            content: `${data.prismicArtist.data.biography_text.text}`,
          },
          {
            name: "og:description",
            content: `${data.prismicArtist.data.biography_text.text}`,
          },
          {
            name: "twitter:title",
            content: `${data.prismicArtist.data.title.text} | ${data.prismicSeo.data.seo_title}`,
          },
          {
            name: "twitter:description",
            content: `${data.prismicArtist.data.biography_text.text}`,
          },
          {
            property: "og:url",
            content: location.href,
          },
          {
            property: "twitter:url",
            content: location.href,
          },
        ]}
      />
      <Page>
        <Grid height={height}>
          <div className="left-column">
            <div className="sticky">
              <div className="content-container">
                <Address />
                <CurrentExhibition />

                <Navigation showArtists={true} hideExhibitions={false} />
              </div>

              <Media
                queries={{ medium: "(min-width: 901px)" }}
                defaultMatches={{ medium: windowWidth === 901 }}
                render={() => <Mailchimp />}
              />
            </div>
          </div>

          <div className="right-column">
            <div className="header">
              <div
                className="exhibition-title"
                dangerouslySetInnerHTML={{
                  __html: data.prismicArtist.data.title.html,
                }}
              />

              <div
                className="exhibition-text"
                dangerouslySetInnerHTML={{
                  __html: data.prismicArtist.data.biography_text.html,
                }}
              />
            </div>

            <ArtworksContainer>
              <ol>{artworks}</ol>
            </ArtworksContainer>
          </div>

          <Media
            queries={{ medium: "(max-width: 900px)" }}
            defaultMatches={{ medium: windowWidth === 900 }}
            render={() => (
              <div className="mobile-mailchimp-container">
                <Mailchimp />
              </div>
            )}
          />
        </Grid>
      </Page>

      {/* {isLightboxOpen && (
        <Lightbox
          artist={data.prismicArtist.data.artist}
          artwork={artwork}
          isLightboxOpen={isLightboxOpen}
          setLightBoxOpen={setLightBoxOpen}
        />
      )} */}

      <form
        hidden
        name="artwork-enquiry"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="artwork-enquiry" />
        {data.prismicArtist.data.artworks.map((artwork, index) => (
          <input
            key={`single_hidden_subject_${data.prismicArtist.data.title.text}_${index}`}
            type="hidden"
            name="subject"
            value={`${artwork.artwork_title.text} Enquiry`}
          />
        ))}

        <input type="hidden" name="email" />
      </form>
    </>
  );
};

export default withPrismicPreview(Artist);

export const query = graphql`
  query Artist($uid: String!) {
    prismicSeo {
      data {
        seo_title
      }
    }
    prismicArtist(uid: { eq: $uid }) {
      id
      data {
        title {
          html
          text
        }
        biography_text {
          html
          text
        }
        artworks {
          image {
            alt
            dimensions {
              height
              width
            }
            fluid(
              imgixParams: { auto: "format" }
              srcSetBreakpoints: [1400, 1600, 2400, 3200, 3600]
            ) {
              src
              srcSet
              sizes
            }
          }
          artwork_title {
            text
            html
          }
          artwork_text {
            html
          }
        }
      }
    }
  }
`;
