import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";

const ExhibitionsContainer = styled.div`
  align-self: flex-start;
  width: 100%;

  margin: 0 0 1em 0;

  // @media (max-width: 900px) {
  //   margin: 40px 0;
  // }

  & h1 {
    margin: 0 0 1em 0;
  }

  & .single-exhibition {
    margin: 0 0 1em 0;

    & p {
      margin: 0;

      & .active-exhibition {
        opacity: 0;
        visibility: hidden;
        transition: 250ms opacity ease;
      }
    }

    & a.active {
      & p {
        & .active-exhibition {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    &:hover {
      & .active-exhibition {
        opacity: 1;
        visibility: visible;
      }
    }

    & a {
      transition: 150ms color ease;

      &:hover {
        color: #6f6d6d;
      }
    }
  }
`;

export const CurrentExhibition = () => {
  const data = useStaticQuery(graphql`
    {
      prismicHomepage {
        data {
          exhibition_heading
          current_exhibitions {
            exhibition {
              document {
                ... on PrismicExhibition {
                  id
                  url
                  data {
                    artist {
                      text
                    }
                    title {
                      text
                    }
                    start_date(formatString: "DD MMMM")
                    end_date(formatString: "DD MMMM YYYY")
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const currentExhibitions = data.prismicHomepage.data.current_exhibitions
    .filter(exhibition => exhibition.exhibition.document !== null)
    .map((exhibition, index) => (
      <li key={exhibition.exhibition.document.id} className="single-exhibition">
        <Link to={exhibition.exhibition.document.url} activeClassName="active">
          <p>{exhibition.exhibition.document.data.artist.text}</p>
          <p>{exhibition.exhibition.document.data.title.text}</p>
          <p>
            {exhibition.exhibition.document.data.start_date}
            {" – "}
            {exhibition.exhibition.document.data.end_date}
            <span className="active-exhibition"> ←</span>
          </p>
        </Link>
      </li>
    ));

  return (
    <ExhibitionsContainer>
      <h1>{data.prismicHomepage.data.exhibition_heading}</h1>

      <ol>{currentExhibitions}</ol>
    </ExhibitionsContainer>
  );
};
