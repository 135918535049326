import React, { useEffect, useContext } from "react";
import styled from "styled-components";
import { use100vh } from "react-div-100vh";
import Modal from "react-modal";
import Media from "react-media";

// Components
import { Address } from "../layout/address";
import { EnquiryForm } from "../forms/enquiry-form";

// Context
import WindowWidthContext from "../context/window-width";

const LightboxContainer = styled.div`
  background-color: #fff;
  z-index: 100;
  height: 100%;

  // height: ${props => props.height}px;
  overflow-y: scroll;

  & > div {
    background-color: #fff;

    width: 100%;
    height: 100%;

    // @media (max-width: 900px) {
    //   overflow-y: scroll;
    // }

    & img {
      width: 100%;
      height: 100%;
      max-height: calc(${props => props.height}px - 130px - 40px - 40px);

      object-fit: contain;
      object-position: top left;

      @media (max-width: 900px) {
        max-height: 100%;
      }
    }

    & .address {
      & p {
        // font-size: 20px;
        // line-height: 24px;
        font-size: 16px;
        line-height: 1.15;
        margin: 0;
      }
    }

    & .lightbox-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      padding: 20px;

      position: relative;
      width: 100%;
      height: 100%;

      overflow-y: scroll;

      @media (max-width: 900px) {
        align-content: flex-start;
      }
    }

    & .top-row {
      @media (max-width: 900px) {
        margin: 0 0 40px 0;
      }
    }

    & .address {
      & p {
        margin: 0;
      }
    }

    & .artwork-details-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      & .artwork-details {
        flex: 100%;
        width: 100%;

        margin: 2em 0;

        & .artwork-text {
          & p:first-of-type {
            margin-top: 0;
          }
        }

        & a {
          text-decoration: underline;
        }

        @media (max-width: 900px) {
          margin: 10px 0 40px 0;
        }
      }

      & .artwork-enquiry-container {
        align-self: flex-end;

        flex: 100%;
        width: 100%;
      }

      @media (max-width: 900px) {
        margin: 0 0 20px 0;
      }
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 30px;

  @media (max-width: 900px) {
    display: block;
    grid-template-columns: unset;
    grid-column-gap: unset;
  }
`;

const CloseLightbox = styled.button`
  position: absolute;
  top: 25px;
  right: 25px;

  color: #000;

  z-index: 100;

  line-height: 1;

  cursor: pointer;

  transition: 150ms color ease;

  &:hover {
    color: #6f6d6d;
  }

  @media (max-width: 320px) {
    position: relative;
    top: 0;
    right: 0;

    margin: 1em 0 0 0;
  }
`;

Modal.setAppElement("#___gatsby");

export const Lightbox = ({
  artist,
  artwork,
  isLightboxOpen,
  setLightBoxOpen,
}) => {
  const windowWidth = useContext(WindowWidthContext);
  const height = use100vh();

  // useEffect(() => {
  //   if (isLightboxOpen) {
  //     document.body.style.overflow = "hidden";
  //   } else {
  //     document.body.style.overflow = "";
  //   }
  // }, [isLightboxOpen]);

  useEffect(() => {
    if (typeof window !== undefined) {
      document.addEventListener("keydown", event => onEscape(event));
    }
  }, [isLightboxOpen]);

  const onEscape = event => {
    if (event.keyCode === 27 && isLightboxOpen === true) {
      setLightBoxOpen(false);

      // document.body.style.overflow = "";
    }
  };

  return (
    <Modal
      isOpen={isLightboxOpen}
      className="product-lighbox-modal"
      contentLabel={artwork.artwork_title.text}
      shouldFocusAfterRender={true}
      onRequestClose={() => setLightBoxOpen(false)}
    >
      <LightboxContainer height={height}>
        <div className="container">
          <div className="lightbox-wrapper">
            <div className="top-row">
              <Address margin={false} />

              <CloseLightbox
                onClick={() => {
                  // document.body.style.overflow = "";
                  setLightBoxOpen(false);
                }}
              >
                <Media
                  queries={{ medium: "(max-width: 900px)" }}
                  defaultMatches={{ medium: windowWidth === 900 }}
                  render={() => `← Back`}
                />

                <Media
                  queries={{ medium: "(min-width: 901px)" }}
                  defaultMatches={{ medium: windowWidth === 901 }}
                  render={() => `← Back to exhibition`}
                />
              </CloseLightbox>
            </div>
            <div className="bottom-row">
              <Grid>
                {artwork.image.fluid !== null && (
                  <img
                    srcSet={artwork.image.fluid.srcSet}
                    src={artwork.image.fluid.src}
                    alt={artwork.image.alt}
                    loading={`lazy`}
                  />
                )}
                <div className="artwork-details-container">
                  <div className="artwork-details">
                    <div
                      className="artist-name"
                      dangerouslySetInnerHTML={{
                        __html: artist.html,
                      }}
                    />
                    <div
                      className="artwork-title"
                      dangerouslySetInnerHTML={{
                        __html: artwork.artwork_title.html,
                      }}
                    />
                    <div
                      className="artwork-text"
                      dangerouslySetInnerHTML={{
                        __html: artwork.artwork_text.html,
                      }}
                    />
                  </div>

                  <div className="artwork-enquiry-container">
                    <EnquiryForm
                      title={`${artist.text} – ${artwork.artwork_title.text}`}
                    />
                  </div>
                </div>
              </Grid>
            </div>
          </div>
        </div>
      </LightboxContainer>
    </Modal>
  );
};
