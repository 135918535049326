import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import addToMailchimp from "gatsby-plugin-mailchimp";

const MailchimpFormContainer = styled.div`
  position: relative;
  align-self: flex-end;

  width: 100%;
  max-width: 340px;

  margin: 0 0 50px 0;

  @media (max-width: 900px) {
    margin: 40px 0 0 0;
  }

  & h2 {
    margin: 0 0 10px 0;
    padding: 0 0 5px 0;

    border-bottom: 1px solid #000;
  }

  & input {
    ::-webkit-input-placeholder {
      font-family: "Times New Roman", "Times", TimesNewRoman, Times New Roman,
        Times, Baskerville, Georgia, serif;

      font-size: 16px;
      line-height: 125%;
      color: #6f6d6d;
    }
    ::-moz-placeholder {
      font-family: "Times New Roman", "Times", TimesNewRoman, Times New Roman,
        Times, Baskerville, Georgia, serif;

      font-size: 16px;
      line-height: 125%;
      color: #6f6d6d;
    }
    :-ms-input-placeholder {
      font-family: "Times New Roman", "Times", TimesNewRoman, Times New Roman,
        Times, Baskerville, Georgia, serif;

      font-size: 16px;
      line-height: 125%;
      color: #6f6d6d;
    }
    :-moz-placeholder {
      font-family: "Times New Roman", "Times", TimesNewRoman, Times New Roman,
        Times, Baskerville, Georgia, serif;

      font-size: 16px;
      line-height: 125%;
      color: #6f6d6d;
    }
    ::placeholder {
      font-family: "Times New Roman", "Times", TimesNewRoman, Times New Roman,
        Times, Baskerville, Georgia, serif;

      font-size: 16px;
      line-height: 125%;
      color: #6f6d6d;
    }
  }

  & .gdpr-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    & p {
      margin: 0;
    }

    & .gdpr-checkbox {
      width: 16px;
      height: 16px;

      position: relative;

      margin: 0 10px 0 0;
    }

    & .gdpr-checkbox label {
      width: 14px;
      height: 14px;
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      border: 1px solid #aeacac;
      border-radius: 100%;
    }

    & .gdpr-checkbox label:after {
      content: "";
      width: 14px;
      height: 14px;
      position: absolute;
      top: -1px;
      left: -1px;
      right: 0;
      bottom: 0;
      border-radius: 100%;

      border: 1px solid #aeacac;
      background-color: #aeacac;

      opacity: 0;
    }
    & .gdpr-checkbox label:hover::after {
      opacity: 1;
    }
    & .gdpr-checkbox input[type="checkbox"] {
      width: 16px;
      height: 16px;

      visibility: hidden;
    }
    & .gdpr-checkbox input[type="checkbox"]:checked + label:after {
      opacity: 1;
    }

    @media (max-width: 600px) {
      margin: 0 0 30px 0;

      &.display-on-desktop {
        display: none;
      }

      &.display-on-mobile {
        display: flex;
      }
    }
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  box-sizing: content-box;

  & p.error-message {
    margin-bottom: 0;
  }

  & .field {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    border: 1px solid #aeacac;
    border-radius: 3px;

    width: 100%;
    margin: 0 0 10px 0;
  }

  & input {
    width: calc(100% - 80px);

    font-size: 16px;
    line-height: 125%;

    padding: 7px;
    margin: 0;

    background-color: transparent;
    -webkit-appearance: none;

    border: 0;

    color: #000;
    position: relative;

    &:focus {
      outline: none;
    }
  }
`;

const ThankYouMessage = styled.div`
  font-size: 16px;
  line-height: 125%;

  & p {
    margin: 0 0 10px 0;
  }
`;

const SignUpButton = styled.button`
  font-size: 16px;
  line-height: 125%;

  -webkit-appearance: none;

  border: 0;
  margin: 0;

  position: relative;

  cursor: pointer;
  color: #6f6d6d;

  &:focus {
    outline: none;
  }

  border: 0;
  padding: 7px;

  transition: 150ms color ease;

  &:hover {
    color: #000;
  }
`;

const PrivacyMessage = styled.div`
  width: 100%;
  max-width: 320px;

  &.display-on-mobile {
    display: none;
  }

  & p {
    margin: 10px 0 0 0;
  }

  & a {
    border-bottom: 1px solid #000;

    &:hover {
      color: #000;
      border-bottom: 1px solid #000;
    }
  }

  @media (max-width: 1024px) {
    width: 100%;
  }

  @media (max-width: 600px) {
    max-width: 100%;

    &.display-on-desktop {
      display: none;
    }

    &.display-on-mobile {
      display: block;
    }
  }
`;

const GDPRContainer = styled.div`
  & p {
    & a {
      transition: 150ms color ease;

      &:hover {
        color: #6f6d6d;
      }
    }
  }
`;

export const Mailchimp = ({
  content,
  tag,
  desktopWidth,
  desktopMargin,
  mobileMargin,
  primaryColor,
  mobileMessage,
}) => {
  const [mailchimpMessage, setMailchimpMessage] = useState(null);
  const [mailchimpErrorMessage, setMailchimpErrorMessage] = useState(false);

  const [email, setEmail] = useState("");
  const [formMessage, setFormMessage] = useState(null);
  const [isGDPRChecked, setIsGDPRChecked] = useState(true);

  // 1. via `.then`
  const handleSubmit = e => {
    e.preventDefault();

    addToMailchimp(email) // listFields are optional if you are only capturing the email address.
      .then(data => {
        // I recommend setting data to React state
        // but you can do whatever you want (including ignoring this `then()` altogether)
        if (data.result === "error") {
          if (data.msg === "The email you entered is not valid.") {
            setFormMessage("Invalid E-mail");
          }

          if (data.msg.includes("already subscribed")) {
            setFormMessage(`E-mail is already subscribed`);
          }
        } else {
          setMailchimpMessage(
            "Thank you for signing up to the ADZ Gallery mailing list."
          );
          setEmail("");
          setFormMessage(null);
          e.target.reset();
        }
      })
      .catch(() => {
        // unnecessary because Mailchimp only ever
        // returns a 200 status code
        // see below for how to handle errors
      });
  };

  useEffect(() => {
    if (mailchimpMessage !== null) {
      const resetFormVisbility = setTimeout(() => {
        setMailchimpMessage(null);
        setFormMessage(null);
      }, 5000);
      return () => clearTimeout(resetFormVisbility);
    }
  }, [mailchimpMessage]);

  return (
    <MailchimpFormContainer>
      <h2>Gallery Mailing List</h2>

      <form onSubmit={e => handleSubmit(e)} className="mailchimp-signup-form">
        {mailchimpMessage === null ? (
          <InputContainer>
            <div className="field">
              <input
                type="email"
                onChange={e => setEmail(e.target.value)}
                value={email}
                placeholder={
                  formMessage !== null ? formMessage : `Enter your e-mail`
                }
                name="email"
                required
                autoComplete="email"
              />
              <SignUpButton type="submit">Send</SignUpButton>
            </div>

            <GDPRContainer
              // visibility={mailchimpMessage === null ? `visible` : `hidden`}
              className="gdpr-container"
            >
              <div className="gdpr-checkbox">
                <input
                  type="checkbox"
                  id="gdpr-checkbox"
                  name="gdpr-checkbox"
                  checked={isGDPRChecked}
                  onChange={() => setIsGDPRChecked(!isGDPRChecked)}
                  required
                />
                <label htmlFor="gdpr-checkbox"></label>
              </div>

              <p>
                I accept the{" "}
                <Link to={`/privacy-policy`}>cookie & privacy policy</Link>
              </p>
            </GDPRContainer>
          </InputContainer>
        ) : (
          <ThankYouMessage>{mailchimpMessage}</ThankYouMessage>
        )}
      </form>
    </MailchimpFormContainer>
  );
};
