import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "gatsby";

// Utils
import { encodeForm } from "../utils/encode-form";

const EnquiryFormContainer = styled.div`
  position: relative;
  align-self: flex-end;

  width: 100%;
  max-width: 340px;

  & h3 {
    margin: 0 0 10px 0;
    padding: 0 0 5px 0;

    border-bottom: 1px solid #000;
  }

  & p {
    margin: 10px 0;
  }

  & input {
    ::-webkit-input-placeholder {
      font-family: "Times New Roman", "Times", TimesNewRoman, Times New Roman,
        Times, Baskerville, Georgia, serif;

      font-size: 16px;
      line-height: 125%;
      color: #6f6d6d;
    }
    ::-moz-placeholder {
      font-family: "Times New Roman", "Times", TimesNewRoman, Times New Roman,
        Times, Baskerville, Georgia, serif;

      font-size: 16px;
      line-height: 125%;
      color: #6f6d6d;
    }
    :-ms-input-placeholder {
      font-family: "Times New Roman", "Times", TimesNewRoman, Times New Roman,
        Times, Baskerville, Georgia, serif;

      font-size: 16px;
      line-height: 125%;
      color: #6f6d6d;
    }
    :-moz-placeholder {
      font-family: "Times New Roman", "Times", TimesNewRoman, Times New Roman,
        Times, Baskerville, Georgia, serif;

      font-size: 16px;
      line-height: 125%;
      color: #6f6d6d;
    }
    ::placeholder {
      font-family: "Times New Roman", "Times", TimesNewRoman, Times New Roman,
        Times, Baskerville, Georgia, serif;

      font-size: 16px;
      line-height: 125%;
      color: #6f6d6d;
    }
  }

  & .gdpr-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    & p {
      margin: 0;
    }

    & .gdpr-checkbox {
      width: 16px;
      height: 16px;

      position: relative;

      margin: 0 10px 0 0;
    }

    & .gdpr-checkbox label {
      width: 14px;
      height: 14px;
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      border: 1px solid #aeacac;
      border-radius: 100%;
    }

    & .gdpr-checkbox label:after {
      content: "";
      width: 14px;
      height: 14px;
      position: absolute;
      top: -1px;
      left: -1px;
      right: 0;
      bottom: 0;
      border-radius: 100%;

      border: 1px solid #aeacac;
      background-color: #aeacac;

      opacity: 0;
    }
    & .gdpr-checkbox label:hover::after {
      opacity: 1;
    }
    & .gdpr-checkbox input[type="checkbox"] {
      width: 16px;
      height: 16px;

      visibility: hidden;
    }
    & .gdpr-checkbox input[type="checkbox"]:checked + label:after {
      opacity: 1;
    }

    @media (max-width: 600px) {
      margin: 0 0 30px 0;

      &.display-on-desktop {
        display: none;
      }

      &.display-on-mobile {
        display: flex;
      }
    }
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  box-sizing: content-box;

  & .field {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    border: 1px solid #aeacac;
    border-radius: 3px;

    width: 100%;
    margin: 0 0 10px 0;
  }

  & input {
    width: calc(100% - 80px);

    font-size: 16px;
    line-height: 125%;

    padding: 7px;
    margin: 0;

    background-color: transparent;
    -webkit-appearance: none;

    border: 0;

    color: #000;
    position: relative;

    &:focus {
      outline: none;
    }
  }
`;

const ThankYouMessage = styled.div`
  font-size: 16px;
  line-height: 125%;

  & p {
    margin: 0 0 10px 0;
  }
`;

const SignUpButton = styled.button`
  font-size: 16px;
  line-height: 125%;

  -webkit-appearance: none;

  border: 0;
  margin: 0;

  position: relative;

  cursor: pointer;
  color: #6f6d6d;

  &:focus {
    outline: none;
  }

  border: 0;
  padding: 7px;

  transition: 150ms color ease;

  &:hover {
    color: #000;
  }
`;

const GDPRContainer = styled.div`
  & p {
    & a {
      transition: 150ms color ease;

      &:hover {
        color: #6f6d6d;
      }
    }
  }
`;

export const EnquiryForm = ({ title }) => {
  const [email, setEmail] = useState("");
  const [formMessage, setFormMessage] = useState(null);
  const [isGDPRChecked, setIsGDPRChecked] = useState(true);

  useEffect(() => {
    if (formMessage !== null) {
      const resetFormVisbility = setTimeout(() => {
        setFormMessage(null);
      }, 5000);
      return () => clearTimeout(resetFormVisbility);
    }
  }, [formMessage]);

  const handleSubmit = e => {
    e.preventDefault();
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encodeForm({
        "form-name": `artwork-enquiry`,
        subject: `${title} Enquiry`,
        email: email,
      }),
    })
      .then(() => {
        setFormMessage(
          "Thank you for submitting your enquiry. We'll be in touch soon."
        );
      })
      .catch(error => {
        console.log(error);
        setFormMessage(
          "An error occurred while submitting your enquiry. Please email us directly at: info@adz.gallery"
        );
      });
  };

  return (
    <EnquiryFormContainer>
      <h3>Enquire</h3>

      {formMessage === null ? (
        <>
          <p>For more information, email below</p>

          <form
            name="artwork-enquiry"
            onSubmit={e => handleSubmit(e)}
            className="mailchimp-signup-form"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" name={`artwork-enquiry`} />
            <input type="hidden" name="subject" value={`${title} Enquiry`} />

            <InputContainer>
              <div className="field">
                <input
                  type="email"
                  onChange={e => setEmail(e.target.value)}
                  value={email}
                  placeholder={`Enter your e-mail`}
                  name="email"
                  required
                  autoComplete="email"
                />
                <SignUpButton type="submit">Send</SignUpButton>
              </div>

              <GDPRContainer className="gdpr-container">
                <div className="gdpr-checkbox">
                  <input
                    type="checkbox"
                    id="gdpr-checkbox"
                    name="gdpr-checkbox"
                    checked={isGDPRChecked}
                    onChange={() => setIsGDPRChecked(!isGDPRChecked)}
                    required
                  />
                  <label htmlFor="gdpr-checkbox"></label>
                </div>

                <p>
                  I accept the{" "}
                  <Link to={`/privacy-policy`}>cookie & privacy policy</Link>
                </p>
              </GDPRContainer>
            </InputContainer>
          </form>
        </>
      ) : (
        <ThankYouMessage>{formMessage}</ThankYouMessage>
      )}
    </EnquiryFormContainer>
  );
};
