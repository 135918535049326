import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

// Components
import { Exhibitions } from "./exhibitions";
import { Artists } from "./artists";

const Nav = styled.nav`
  @media (max-width: 767px) {
    margin: 0 0 40px 0;
  }

  & ol {
    & li {
      & a {
        transition: 250ms color ease;

        & span {
          opacity: 0;
          transition: 250ms opacity ease;
        }

        &:hover,
        &.current {
          color: #6f6d6d;

          & span {
            opacity: 1;
          }
        }
      }
    }
  }
`;

export const Navigation = ({
  hideArtists,
  hideExhibitions,
  showExhibitions,
  showArtists,
  showBackButton,
  year,
}) => {
  return (
    <Nav>
      <ol>
        {hideArtists !== true && (
          <li>
            <Link to={`/artists/`} activeClassName="current">
              Artists <span className="active-page"> ←</span>
            </Link>

            {showArtists === true && <Artists />}
          </li>
        )}

        {showBackButton === true ? (
          <li className="back-button">
            <Link to={`/exhibitions/`}>
              Back <span className="active-page"> ←</span>
            </Link>
          </li>
        ) : (
          <>
            {hideExhibitions !== true && (
              <li>
                <Link to={`/exhibitions/`} activeClassName="current">
                  Exhibitions <span className="active-page"> ←</span>
                </Link>

                {showExhibitions === true && <Exhibitions />}
              </li>
            )}
          </>
        )}
      </ol>
    </Nav>
  );
};
