import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";

const ExhibitionsContainer = styled.div`
  align-self: flex-start;
  width: 100%;

  // margin: 1em 0;

  // @media (max-width: 900px) {
  //   margin: 40px 0;
  // }

  // & h1 {
  //   margin: 0 0 1em 0;
  // }

  & a.year-link {
    letter-spacing: 0.15px;
  }

  & ol {
    margin: 1em 0;
  }

  & .single-exhibition {
    margin: 0 0 1em 0;

    & p {
      margin: 0;

      & .active-exhibition {
        opacity: 0;
        visibility: hidden;
        transition: 250ms opacity ease;
      }
    }

    & a.active {
      & p {
        & .active-exhibition {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    &:hover {
      & .active-exhibition {
        opacity: 1;
        visibility: visible;
      }
    }

    & a {
      transition: 150ms color ease;

      &:hover {
        color: #6f6d6d;
      }
    }
  }
`;

export const RelatedExhibitionsByYear = ({ activeYear }) => {
  const data = useStaticQuery(graphql`
    {
      allPrismicExhibition(sort: { fields: data___start_date, order: DESC }) {
        edges {
          node {
            id
            url
            data {
              artist {
                text
              }
              title {
                text
              }
              year: start_date(formatString: "YYYY")
              start_date(formatString: "DD MMMM YYYY")
              end_date(formatString: "DD MMMM YYYY")
            }
          }
        }
      }
    }
  `);

  const groupedData = data.allPrismicExhibition.edges.reduce((r, e) => {
    // get first letter of name of current element
    let group = e.node.data.year;
    // start_date.split(" ")[2];
    // if there is no property in accumulator with this letter create it
    if (!r[group]) r[group] = { group, children: [e] };
    // if there is push current element to children array for that letter
    else r[group].children.push(e);
    // return accumulator
    return r;
  }, {});

  // since data at this point is an object, to get array of values
  // we use Object.values method
  const formattedData = Object.values(groupedData);

  const exhibitions = formattedData
    .reverse()
    .filter(year => year.group === activeYear)
    .map((year, index) => {
      const yearExhibitions = year.children.map((exhibition, index) => (
        <li key={exhibition.node.id} className="single-exhibition">
          <Link to={exhibition.node.url} activeClassName="active">
            <p>{exhibition.node.data.artist.text}</p>
            <p>{exhibition.node.data.title.text}</p>
            <p>
              {exhibition.node.data.start_date}
              {" – "}
              {exhibition.node.data.end_date}
              <span className="active-exhibition"> ←</span>
            </p>
          </Link>
        </li>
      ));

      return (
        <li>
          <ol>{yearExhibitions}</ol>
        </li>
      );
    });

  return (
    <ExhibitionsContainer>
      <Link to={`/exhibition/${activeYear}/`} className="year-link">
        {activeYear}
      </Link>
      <ol>{exhibitions}</ol>
    </ExhibitionsContainer>
  );
};
